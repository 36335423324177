@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.textNav {
  font-family: "Roboto", sans-serif;
}

:root {
  --main-color: #f0940d;
  --light-color: #f0f3f2;
  --mainbackground-color: #000000;
  --font-family: "Montserrat", sans-serif;
  --rating-color: #ffc908;
}

body {
  background-color: #ffffff;
  color: black;
  font-family: var(--font-family) !important;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #ffffff; /* Still use your main color */
    color: black;
  }
}

.main-color {
  color: var(--main-color);
}
.mainBackGround {
  background-color: var(--mainbackground-color);
}

.rating-color {
  color: var(--rating-color);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  /* Uncomment below if needed */
  margin-left: 0 !important;
  margin-right: 0 !important;
}
